.contact-header {
    display: block;
    text-align: center;
}

.contact-header > * {
    margin: 15px 0px;
}

.contact-header h1 {
    font-size: 3rem;
    font-weight: bold;
}

.contact-header h2 {
    font-size: 1.4rem;
}

.contact-content {
    max-width: 50ch;
    text-align: center;
    line-height: 30px;
}

.contact-content span, .contact-content a {
    font-weight: bold;
    color: indianred;
}

.contact-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.contact-buttons form {
    margin: 15px 15px;
}

.contact-buttons a {
    font-size: 1.2rem;
    padding: 15px 45px;
    color: indianred;
    background-color: transparent;
    border: 1px solid indianred;
    border-radius: 3px;
    transition: 0.3s ease;
}

.contact-buttons a:hover {
    border: 1px solid #191919;
    background-color: indianred;
    color: whitesmoke;
    -webkit-transition: color 300ms;
    -ms-transition: color 300ms;
    transition: color 300ms;
    -webkit-transition: background-color 300ms;
    -ms-transition: background-color 300ms;
    transition: background-color 300ms;

}

@media (max-width: 900px) {
    .contact {
        display: block;
    }
    .contact-header h1 {
        font-size: 2rem;
    }
    .contact-header h2 {
        font-size: 1.2rem;
    }
    .contact-content {
        margin: 0px 25px;
    }
}

@media (max-width: 300px) {
    .contact {
        font-size: 0.7rem;
        max-width: 40ch;
        margin: 0 25px;
    }
    .contact-buttons {
        display: block;
        text-align: center;
    }
}

