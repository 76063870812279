.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10000;
    position: sticky;
    top: 0;
    background-color: #141414;
    padding: 15px 2%;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    transition: top 0.4s;
}


.home-link, .links {
    display: flex;
    overflow: hidden;
}

.link {
    padding: 5px 15px;
}

.link nav {
    font-size: 1.2rem;
    color: grey;
    transition: 0.3s;
}

.link nav:hover, .project-info a:hover {
    color:indianred;
    -webkit-transition: color 300ms;
    -ms-transition: color 300ms;
    transition: color 300ms;
}

@media (max-width: 300px) {
    .link nav {
        font-size: 0.7rem;
    }
}