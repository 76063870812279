.projects {
    display: grid;
    place-items: center;
    margin: 0px 50px;
}

.projects h1 {
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    margin-top: 50px;
    color: lightgrey;
}

.projects-container {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 60px 15px;
}

.project {
    display: block;
    text-align: left;
    position: relative;
    width: 324px; height: 305px;
    background-color: #191919;
    border-radius: 3px;
    margin: 20px 7px;
    float: left;
    top: 0;
    transition: 0.2s ease;
}

.project:hover {
    top: -7px;
}

.project:hover .project-title {
    color: indianred;
    -webkit-transition: color 200ms linear;
    -ms-transition: color 200ms linear;
    transition: color 200ms linear;
}

.project-links {
    display: flex;
    justify-content: space-between;
    padding: 10% 9%;
    font-size: 1.5rem;
}

#git-link:hover {
    color: indianred;
    -webkit-transition: color 200ms linear;
    -ms-transition: color 200ms linear;
    transition: color 200ms linear;
}

.project-title {
    color: lightgrey;
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    top: 30%; left: 9%;
}

.project-description {
    position: absolute;
    top: 40%; left: 9%;
    max-width: 81%;
    line-height: 25px;
}

.project-stack {
    display: flex;
    position: absolute;
    bottom: 10%; left: 9%;
    color: #606060;
}

.project-stack div {
    margin-right: 15px;
}

@media (max-width: 1200px) {
    .projects-container {
        display: block;
    }
    .projects {
        margin: 0 auto;
    }
    .project {
        width: 250px; height: 300px;
        align-self: center;
    }
}

@media (max-width: 300px) {
    .project {
        width: 200px; height: 250px;
        font-size: 0.8rem;
    }
}
