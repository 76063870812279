.footer-container {
    position: fixed;
    bottom: 0; left: 0;
    margin: 0 2%;
}

.footer-container ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socials i {
    position: relative;
    top: 0;
    font-size: 20px;
    transition: 0.3s ease;
    padding: 13px 0px;
}

.socials i:hover {
    top: -3px;
    color: indianred;
}

.vl {
    border-left: 1px solid indianred;
    min-height: 70px;
    margin-top: 20px;
}

@media (max-width: 700px) {
    .footer-container, .email-container {
        display: none;
    }
}