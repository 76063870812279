.home-container {
    display: flex;
    align-items: center;
    text-align: left;
    min-height: 90vh;
    margin: 0px 25%;
}

.h-overflow {
    overflow: hidden;
}

.home-hero {
    display: block;
}

.home-container h1 {
    font-size: 6rem;
    color: whitesmoke;
}

.home-container h2 {
    font-size: 3rem;
    line-height: 70px;
    color:indianred;
}

.home-container h3 {
    font-size: 1rem;
    color: lightgrey;
    max-width: 50ch;
    line-height: 25px;
}

@media (max-width: 900px) {
    .home-container {
        margin: 0px 15%;
    }
    .home-hero {
        line-height: 40px;
        margin: 15px 0px;
    }
    .home-hero h1 {
        font-size: 40px;
    }
    .home-hero h2 {
        font-size: 25px;
        line-height: 30px;
    }
    .home-hero h3 {
        font-size: 12px;
    }
}

* {
    box-sizing: border-box;
}