.about h1 {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 30px;
}

.about img {
    max-width: 300px; height: auto;
    border-radius: 3px;
    outline: 1px solid indianred;
    outline-offset: 15px;
}

.about-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-content * {
    margin: 0px 15px;
}

.about p {
    line-height: 30px;
    max-width: 50ch;
    font-size: 16px;
    margin-bottom: 15px;
}

.skills h1 {
    text-align: center;
    font-size: 1.4rem;
    margin: 30px 0px;
}

.skills ul {
    columns: 3; -webkit-columns: 3; -moz-columns: 3;
}

.skills li {
    display: flex;
    align-items: center;
    line-height: 30px;
}

.skills i {
    margin-right: 7px;
    font-size: 12px;
    color: indianred;
}

@media (max-width: 920px) {
    .about {
        text-align: center;
    }
    .about-content {
        display: block;
        text-align: center;
        margin: 0px 25px;
        max-width: 40ch;
    }

    .about img {
        height: 150px; width: auto;
        margin: 25px;
    }
}

@media (max-width: 300px) {
    .about * {
        font-size: 0.7rem;
    }
}